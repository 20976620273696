import {connect} from "react-redux";
import {useRouteMatch, withRouter} from "react-router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import useInfoForm from "../../modules/forms/useInfoForm";
import {
    addDataDefs,
    addDataExtra,
    FieldsToRows,
    hideFields, hideFieldsLazy,
    setFetchData,
    setFetchDataLazy
} from "../../modules/information/infoFields";
import Button from "@material-ui/core/Button";
import {Fetch} from "../../common/lib/api-fetch";
import {API_BASE_URL} from "../../constants";
import {showError, showErrorText, showInfo} from "../../common/lib/show-toast";
import _ from 'lodash';
import Navbar from "react-bootstrap/Navbar";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '100vh'
    },
    textField: {
        width: '100%'
    },
    dateField: {},
    label: {
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const valueDef = {
    gender: {
        label: 'Kjønn',
        type: 'select',
        value: '',
        position: 0,
        required: 'Du må fylle ut kjønn',
        possibleValues: [{key: 'kvinne', value: 'Kvinne'}, {key: 'mann', value: 'Mann'}, {key: 'annet', value: 'Annet / Vil ikke oppgi'}],
        helperText: "Nødvendig"
    },
    firstname: {
        label: 'Fornavn',
        type: 'text',
        value: '',
        position: 1,
        required: 'Du må fylle ut fornavn',
        regex: '\\w+'
    },
    lastname: {
        label: 'Etternavn',
        type: 'text',
        value: '',
        position: 2,
        required: 'Du må fylle ut etternavn',
        regex: '\\w+'
    },
    email: {
        label: 'Epost',
        type: 'email',
        value: '',
        position: 3,
        required: 'Du må fylle inn epost'
    },
    email2: {
        label: 'Epost igjen',
        type: 'email',
        value: '',
        position: 4,
        sameAs: 'email',
        errorText: 'Må være likt Epost',
        post: false,
    },
    mobile: {
        label: 'Mobilnummer',
        type: 'text',
        value: '',
        position: 5,
        required: 'Du må fylle ut mobilnummer',
        regex: '^([0|\\+[0-9]{1,5})?[-0-9 ]{8,}$'
    },
    allergies: {
        label: 'Allergier',
        type: 'text',
        value: '',
        position: 10,
    },
    birthDate: {
        label: 'Fødselsdato',
        type: 'date',
        value: '',
        position: 7,
        helperText: 'Nødvendig for hotellet'
    },
    roomType: {
        label: 'Romtype',
        type: 'select',
        possibleValues: [{
            key: 'enkeltrom',
            value: "Enkeltrom"
        }, {key: 'dobbeltrom1', value: 'Dobbeltrom m/separate senger'}, {
            key: 'dobbeltrom2',
            value: 'Dobbeltrom m/dobbeltseng'
        }],
        value: '',
        required: 'Du må velge romtype',
        position: 8
    },
    sharingPerson: {
        label: 'Om du IKKE har med ledsager, hvem vil du dele rom med?',
        type: 'text',
        value: '',
        position: 9,
        hideOn: {
            field: 'companion',
            value: true
        },
        showOn: [
            {
                field: 'roomType',
                value: 'dobbeltrom1'
            },
            {
                field: 'roomType',
                value: 'dobbeltrom2'
            }
        ],
        helperText: 'Hvem du vil dele dobbeltrommet med'
    },
    companion: {
        label: 'Ledsager',
        type: 'boolean',
        value: false,
        position: 9,
        hideOn: {
            field: 'sharingPerson',
            regex: '.+'
        },
        showOn: [
            {
                field: 'roomType',
                value: 'dobbeltrom1'
            },
            {
                field: 'roomType',
                value: 'dobbeltrom2'
            }
        ],
        helperText: 'Har du med deg ledsager',
        addFieldsOn: true,
        addFields: {
            companionGender: {
                label: 'Ledsager: Kjønn',
                type: 'select',
                value: '',
                position: 0,
                indent: 1,
                required: 'Du må fylle ut kjønn',
                possibleValues: [{key: 'kvinne', value: 'Kvinne'}, {key: 'mann', value: 'Mann'}, {key: 'annet', value: 'Annet / Vil ikke oppgi'}],
                helperText: "Nødvendig"
            },
            companionFirstname: {
                label: 'Ledsager: Fornavn',
                type: 'text',
                value: '',
                position: 1,
                indent: 1,
                required: 'Du må fylle ut fornavn',
                regex: '\\w+',
            },
            companionLastname: {
                label: 'Ledsager: Etternavn',
                type: 'text',
                value: '',
                position: 2,
                indent: 1,
                required: 'Du må fylle ut etternavn',
                regex: '\\w+'
            },
            companionEmail: {
                label: 'Ledsager: Epost',
                type: 'email',
                value: '',
                position: 3,
                indent: 1,
                required: 'Du må fylle ut epost',
            },
            companionMobile: {
                label: 'Ledsager: Mobilnummer',
                type: 'mobile',
                value: '',
                position: 4,
                indent: 1,
                required: 'Du må fylle ut mobilnummer',
            },
            companionAllergies: {
                label: 'Ledsager: Allergier',
                type: 'text',
                value: '',
                indent: 1,
                position: 5,
            },
            companionBirthDate: {
                label: 'Ledsager: Fødselsdato',
                type: 'date',
                value: '',
                position: 6,
                indent: 1,
                helperText: 'Nødvendig fordi...',
            },
        }
    },
    SASeurobonus: {
        label: 'SAS eurobonusnummer',
        type: 'text',
        value: '',
        position: 10,
        helperText: 'Ditt SAS eurobonusnummer'
    },
    passportNumber: {
        label: 'Passnummer',
        type: 'text',
        value: '',
        position: 11,
        helperText: 'Passnummeret ditt, for hotellet'
    },
    passport: {
        label: 'Passinfo',
        type: 'boolean',
        value: true,
        position: 10,
        addFieldsOn: true,
        addFields: {
            passportNumber: {
                label: 'Passnummer',
                type: 'text',
                value: '',
                position: 1,
                indent: 1,
                required: 'Du må fylle ut passnummer',
                helperText: 'Passnummeret ditt, for hotellet'
            },
            passportIssuedDate: {
                label: 'Utstedt dato',
                type: 'date',
                value: '',
                position: 2,
                indent: 1,
                required: 'Du må fylle ut utstedt dato'
            },
            passportExpireDate: {
                label: 'Utgår dato',
                type: 'date',
                value: '',
                position: 3,
                indent: 1,
                required: 'Du må fylle ut utgår dato'
            },
            passportIssueCountry: {
                label: 'Utstederland',
                type: 'text',
                value: '',
                position: 4,
                indent: 1,
                required: 'Du må fylle ut utstederland'
            },
            passportNationality: {
                label: 'Nasjonalitet',
                type: 'text',
                value: '',
                position: 5,
                indent: 1,
                required: 'Du må fylle ut nasjonalitet'
            }
        }
    },
    companyInfo: {
        label: 'Firmainformasjon',
        type: 'boolean',
        value: true,
        position: 12,
        addFieldsOn: true,
        addFields: {
            companyName: {
                label: 'Firmanavn',
                type: 'text',
                value: '',
                position: 1,
                indent: 1,
                required: 'Du må fylle inn firmanavn',
                helperText: 'Navnet på firmaet du representerer'
            },
            companyEmail: {
                label: 'Epost for faktura',
                type: 'email',
                value: '',
                position: 2,
                indent: 1,
                required: 'Du må fylle inn epost for faktura',
                helperText: 'Epost adressen til faktura mottaker'
            },
            companyOrgNr: {
                label: 'Organisasjonsnummer',
                type: 'text',
                value: '',
                position: 3,
                indent: 1,
                helperText: 'Organisasjonsnummeret, for enklere å finne firmainformasjon'
            },
            companyAddress: {
                label: 'Adresse',
                type: 'text',
                value: '',
                indent: 1,
                position: 4,
            },
            companyAddressPostNr: {
                label: 'Postnummer',
                type: 'text',
                value: '',
                indent: 1,
                position: 5
            },
            companyAddressPostSted: {
                label: 'Poststed',
                type: 'text',
                value: '',
                indent: 1,
                position: 6
            }
        },
    },
    note: {
        label: 'Ekstra informasjon',
        type: 'text',
        lines: 5,
        value: '',
        position: 50,
        helperText: 'Ekstra informasjon til turoperatør'
    }
};


const AddMember = ({user, history}) => {
    const classes = useStyles();
    const [isUser, setIsUser] = useState(user.isUser);
    const [values, setValues] = useState({});
    const [loadTrip, setLoadTrip] = useState(false);
    const [tripData, setTripData] = useState({});
    const [logo, setLogo] = useState("");
    const [oldRoomType, setOldRoomType] = useState(null);
    const [availableRooms, setAvailableRooms] = useState({singleRooms: 0, doubleRooms: 0, halfDoubleRooms: 0});
    let tripUser = useRouteMatch({
        path: '/tur/:uuid/paamelding/:userUuid',
        sensitive: true
    });
    const [openForRegistration, setOpenForRegistration] = useState(true);
    const [roomCheck, setRoomCheck] = useState((key, value) => false);

    const [tripUserUuid, setTripUserUuid] = useState(tripUser ? tripUser.params.userUuid : '0');

    let form = useInfoForm(values, setValues);

    let trip = useRouteMatch({
        path: "/tur/:uuid/",
        sensitive: true
    });
    const [tripUuid] = useState(trip ? trip.params.uuid : "0");

    useEffect(() => {
        setIsUser(user.isUser);
    }, [user]);

    useEffect(() => {
        setTripUserUuid(tripUser ? tripUser.params.userUuid : "0");
    }, [tripUser])

    useEffect(() => {
        if (tripUuid !== '0') {
            Fetch(API_BASE_URL + "/trip/" + tripUuid + "/available", "GET").then(result => {
                setAvailableRooms(result.data);
            })
        }
    }, [tripUuid])

    const checkAvailableRoom = () => {
        const field = values.roomType;
        if (!field) return false;
        if (field.value===oldRoomType) return true;
        if (field.value === 'enkeltrom') {
            if (availableRooms.singleRooms <= 0) return false;
            return true;
        }
        if (availableRooms.doubleRooms > 0) return true;
        if (availableRooms.halfDoubleRooms > 0) return true;
        return false;
    }

    useEffect(() => {
        if (tripUserUuid !== "0" && 'uuid' in tripData) {
            Fetch(API_BASE_URL + "/trip/" + tripUuid + "/member/" + tripUserUuid, "GET").then(result => {
                const data = result.data;
                setOldRoomType(data.roomType);
                let newValues = _.cloneDeep(values);
                setFetchDataLazy({values: newValues, data});
                setFetchDataLazy({values: newValues, data: data.extra});
                hideFieldsLazy({values: newValues, fields: ['email2']});
                newValues.email2.value = newValues.email.value;
                form.calculateFields(newValues);
                form.checkHidden(newValues, 'roomType');
                setValues(newValues);
            }).catch(err => {
                console.log(err);
                showErrorText("Noe gikk galt ved henting av lagrede data");
            })
        }
    }, [tripUserUuid, tripData]);

    useEffect(() => {
        setLoadTrip(true);
    }, [tripUserUuid])

    useEffect(() => {
        setLoadTrip(true);
    }, [openForRegistration])

    useEffect(() => {
        if (loadTrip) {
            Fetch(API_BASE_URL + "/trip/" + tripUuid, 'GET', {}).then(result => {
                let data = result.data;
                setLogo(result.data.logoUuid);
                if (!isUser && (data.locked || new Date(data.changeDate) < Date.now())) {
                    setOpenForRegistration(false);
                } else {
                    setOpenForRegistration(true);
                }
                let newValues = getInitialValues();
                addDataDefs({
                    values: newValues,
                    keys:
                        [
                            data.companion ? 'companion' : '',
                            data.showSASeurobonus ? 'SASeurobonus' : '',
                            data.passportNumber ? 'passportNumber' : '',
                            data.passport ? 'passport' : '',
                            data.showCompanyFields ? 'companyInfo' : ''
                        ],
                    defs: valueDef
                });
                if (data.singleRoomExtraCost > 0) {
                    newValues.roomType.possibleValues[0].value = "Enkeltrom (+" + data.singleRoomExtraCost + " kr pr døgn)";
                }
                if (data.companion) {
                    console.log("Her", data)
                    if (data.showSASeurobonus) {
                        newValues.companion.addFields.companionSASeurobonus = _.cloneDeep(valueDef.SASeurobonus);
                        newValues.companion.addFields.companionSASeurobonus.indent = 1;
                        newValues.companion.addFields.companionSASeurobonus.position = 7;
                        newValues.companion.addFields.companionSASeurobonus.label = 'Ledsager: '+newValues.companion.addFields.companionSASeurobonus.label;
                    }
                    if (data.passportNumber) {
                        newValues.companion.addFields.companionPassportNumber = _.cloneDeep(valueDef.passportNumber);
                        newValues.companion.addFields.companionPassportNumber.indent = 1;
                        newValues.companion.addFields.companionPassportNumber.position = 8;
                        newValues.companion.addFields.companionPassportNumber.label = 'Ledsager: '+newValues.companion.addFields.companionPassportNumber.label;
                    }
                    if (data.passport) {
                        let startPos = 20;
                        for(let key of Object.keys(valueDef.passport.addFields)) {
                            newValues.companion.addFields['companion'+key] = _.cloneDeep(valueDef.passport.addFields[key]);
                            newValues.companion.addFields['companion'+key].position = startPos++;
                            newValues.companion.addFields['companion'+key].indent = 2;
                            newValues.companion.addFields['companion' + key].label = 'Ledsager pass: '+newValues.companion.addFields['companion' + key].label;
                        }
                    }
                    for (let key of Object.keys(data.extra)) {
                        newValues.companion.addFields['companion' + key] = _.cloneDeep(data.extra[key]);
                        newValues.companion.addFields['companion' + key].indent = 1;
                        newValues.companion.addFields['companion' + key].position = 9;
                        newValues.companion.addFields['companion' + key].label = 'Ledsager: '+newValues.companion.addFields['companion' + key].label;
                    }
                }
                addDataExtra({values: newValues, extra: data.extra});
                newValues.roomType.extraErrorCheck = roomCheck;
                setValues(form.calculateFields(newValues));
                setTripData(data);
            }).catch(err => {
                console.log(err);
                showErrorText("Noe gikk galt ved henting av data");
            })
            setLoadTrip(false);
        }
    }, [loadTrip, isUser]);


    const saveNewMember = () => {
        let data = {extra: {}};
        for (let key of [
            'gender',
            'firstname',
            'lastname',
            'email',
            'mobile',
            'allergies',
            'birthDate',
            'roomType',
            'sharingPerson',
            'note',
            'companion'
        ]) {
            data[key] = key in values ? values[key].value : '';
        }

        if (tripData.showSASeurobonus) {
            data.extra.SASeurobonus = values.SASeurobonus.value;
        }

        if (tripData.passportNumber) {
            data.extra.passportNumber = values.passportNumber.value;
        }
        if (tripData.passport) {
            for (let key of Object.keys(values.passport.addFields)) {
                data.extra[key] = values[key].value;
            }
        }
        if (tripData.showCompanyFields) {
            for (let key of Object.keys(values.companyInfo.addFields)) {
                data.extra[key] = values[key].value;
            }
        }
        for (let key of Object.keys(tripData.extra)) {
            data.extra[key] = values[key].value;
        }

        if ('companion' in values && values.companion.hidden)
            data.companion = false;

        if (data.companion)
            data.sharingPerson = '';

        if (data.companion === '')
            data.companion = false;

        if (tripData.companion && data.companion) {
            for (let key of Object.keys(values.companion.addFields)) {
                data.extra[key] = values[key].value;
            }
            for (let key of Object.keys(tripData.extra)) {
                data.extra['companion' + key] = values['companion' + key].value;
            }
        }
        data.tripUuid = tripUuid;
        data.uuid = tripUserUuid !== "0" ? tripUserUuid : null;
        Fetch(API_BASE_URL + "/trip/" + tripUuid + "/postMember", 'POST', data).then(result => {
            console.log(result);
            if (data.uuid !== null) {
                showInfo("Dataene dine er oppdatert");
            } else {
                showInfo("Du er opprettet som medlem av turen. Du skal ha fått en epost");
            }
            history.push("/tur/" + tripUuid);
        }).catch(err => {
            showErrorText("Noe gikk galt i oppdateringen");
        })
        console.log("Saving", data);
    }

    const getInitialValues = () => {
        let newValues = {};
        addDataDefs({
            values: newValues,
            keys:
                ['gender', 'firstname', 'lastname', 'email', 'email2', 'mobile', 'allergies', 'birthDate', 'roomType', 'sharingPerson', 'note'],
            defs: valueDef
        });
        return newValues;
    }

    useEffect(() => {
        let newValues = getInitialValues();
        setValues(form.calculateFields(newValues));
    }, []);

    useEffect(() => {
        if (tripUuid !== "0")
            setLoadTrip(true);
    }, [tripUuid]);

    return (
        <>
            <Container className={classes.root}>
                <Row className="justify-content-center setup-trip" md={8}>
                    <Container>
                        <form noValidate autoComplete="off">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <>
                                    <Row className="mt-3">
                                        <Col md={9}>
                                            <h2>Tur - {tripData.name}</h2>
                                            <p className={"instruction"}>
                                                Fyll inn informasjonen om deg selv
                                            </p>
                                            <Grid container>
                                                <Grid item md={12} className={"instruction"}>Ledige
                                                    enkeltrom: {availableRooms.singleRooms}</Grid>
                                                <Grid item md={12} className={"instruction"}>Ledige
                                                    dobbeltrom: {availableRooms.doubleRooms} {availableRooms.halfDoubleRooms > 0 ? `(+${availableRooms.halfDoubleRooms})` : ''}</Grid>
                                            </Grid>
                                        </Col>
                                        <Col md={3}>
                                            {logo && logo !== "" &&
                                            <img className="mb-2" src={API_BASE_URL + "/image/" + logo}
                                                 style={{height: '100px'}} className="img-fluid"/>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={"ml-3"}>


                                    </Row>
                                    {
                                        openForRegistration && FieldsToRows({values, classes, infoForm: form})
                                    }
                                    {openForRegistration &&
                                    <Row>
                                        <Col>
                                            <Button color={"secondary"} variant="contained"
                                                    disabled={form.hasErrors() || !checkAvailableRoom()}
                                                    onClick={() => saveNewMember()}>
                                                {tripUserUuid === "0" ? `Registrer deg` : `Oppdater`}
                                            </Button>
                                            {
                                                !checkAvailableRoom() &&
                                                    <div className={"error"}>Ingen ledige rom</div>
                                            }
                                        </Col>
                                    </Row>
                                    }
                                    {!openForRegistration &&
                                    <Row>
                                        <Col>
                                            Turen er stengt for påmelding og endringer. Ta kontakt med turleder for mer
                                            informasjon.
                                        </Col>
                                    </Row>
                                    }
                                </>
                            </MuiPickersUtilsProvider>
                        </form>
                    </Container>
                </Row>
            </Container>
        </>
    )
}

const mapStateToProps = state => ({
    user: state.user
});

const connected = connect(mapStateToProps, {})(AddMember);

export default withRouter(connected);
